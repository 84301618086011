<template>
  <div class="search" @click.prevent="$router.push('/search')">
    <input
      name="name"
      type="text"
      placeholder="Поиск"
      class="input input--grey search__input"
      autocomplete="off"
    />
    <div class="search__icon">
      <svg class="icon icon-search ">
        <use xlink:href="#search"></use>
      </svg>
    </div>
  </div>
</template>
