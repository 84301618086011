<template>
  <div class="container">
    <div class="section-home section-home--search">
      <Search />
    </div>

    <div class="section-home section-home--slider" v-if="mainBanner && mainBanner.length > 0">
      <MainBanner
        :sliders="mainBanner"
        :swiperOptions="swiperOptions"
        :classItem="'home__slider'"
      />
    </div>

    <div class="section-home" v-if="advertisementBanner && advertisementBanner.length > 0">
      <StockLinks :stocks="advertisementBanner" />
    </div>

    <div class="section-home" v-if="novelties && novelties.length > 0">
      <div class="home__title title title--second">Новинки</div>
      <div class="home-cards">
        <div class="home-card" v-for="product in novelties" :key="product.id">
          <Product :product="product" />
        </div>
      </div>
    </div>

    <div
      class="section-home section-home--stocks"
      v-if="catalogBanner && catalogBanner.length > 0"
    >
      <div class="home-features">
        <Feature :features="catalogBanner" />
      </div>
    </div>

    <div class="section-home" v-if="hit && hit.length > 0">
      <div class="home__title title title--second">Хит</div>
      <div class="home-cards">
        <div class="home-card" v-for="product in hit" :key="product.id">
          <Product :product="product" />
        </div>
      </div>
    </div>

    <div class="section-home">
      <Bullets />
    </div>

    <div class="section-home" v-if="recommend && recommend.length > 0">
      <div class="home__title title title--second">Советуем</div>
      <div class="home-cards">
        <div class="home-card" v-for="product in recommend" :key="product.id">
          <Product :product="product" />
        </div>
      </div>
    </div>

    <div class="section-home" v-if="offers && offers.length > 0">
      <div class="home__title title title--second">Выгодные предложения</div>
      <div class="home-suggestions">
        <Suggestions :classItem="'home-suggestion'" :banners="offers" />
      </div>
    </div>

    <div class="section-home" v-if="stocks && stocks.length > 0">
      <div class="home__title title title--second">Акции</div>
      <div class="home-cards">
        <div class="home-card" v-for="product in stocks" :key="product.id">
          <Product :product="product" />
        </div>
      </div>
    </div>

    <div class="section-home" v-if="reviews && reviews.length > 0">
      <div class="home__title title title--second">Отзывы</div>
      <div class="home-reviews">
        <Review v-for="review in reviews" :key="review.id" :review="review" />
      </div>
    </div>

<!--    <div class="section-home" v-if="innerBanner && innerBanner.length > 0">-->
<!--      <div class="home-vacancies">-->
<!--        <Suggestions :classItem="'home-vacancy'" :banners="innerBanner"/>-->
<!--      </div>-->
<!--    </div>-->
  </div>

  <div class="section-home section-home--accordeon" v-if="questions && questions.length > 0">
    <div
      class="home-accordeon"
      v-for="question in questions"
      :key="question.id"
    >
      <Accordeon v-for="item in question.items" :key="item.id" :item="item">
        <div class="typography" v-html="item.text"></div>
      </Accordeon>
    </div>
  </div>
</template>

<script>
import store from "@/store/index.js";
import Search from "@/components/button/search";
import MainBanner from "@/components/sliders/main-banner";
import StockLinks from "@/components/cards/stock-links";
import Product from "@/components/cards/product";
import Feature from "@/components/cards/feature";
import Bullets from "@/components/cards/bullets";
import Suggestions from "@/components/cards/suggestions";
import Review from "@/components/cards/review";
import Accordeon from "@/components/cards/accordeon";

export default {
  beforeRouteEnter(to, from, next) {
    if (store.state.home.isLoadedHome)
      next();
    else
      store.dispatch("HOME_FETCH_DATA").then(() => {
        store.dispatch("HOME_FETCH_INFO_FAQ")

        next();
      });
  },

  components: {
    Search,
    MainBanner,
    StockLinks,
    Product,
    Feature,
    Bullets,
    Suggestions,
    Review,
    Accordeon,
  },

  computed: {
    mainBanner() {
      return this.$store.state.home.mainBanner;
    },
    advertisementBanner() {
      return this.$store.state.home.advertisementBanner;
    },
    stocks() {
      return this.$store.state.home.stocks;
    },
    novelties() {
      return this.$store.state.home.novelties;
    },
    recommend() {
      return this.$store.state.home.recommend;
    },
    hit() {
      return this.$store.state.home.hit;
    },
    reviews() {
      return this.$store.state.home.reviews;
    },
    catalogBanner() {
      return this.$store.state.home.catalogBanner;
    },
    questions() {
      return this.$store.state.home.questions;
    },
    innerBanner() {
      return this.$store.state.home.innerBanner;
    },
    offers() {
      return this.$store.state.home.offers;
    },
  },

  data() {
    return {
      swiperOptions: {
        direction: "horizontal",
        loop: true,
        slidesPerView: 1,
        spaceBetween: 10,
      },
    };
  },
};
</script>
