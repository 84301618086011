<template>
  <div class="swiper-container" :class="classItem">
    <swiper
      class="swiper-wrapper"
      ref="mySwiper"
      :auto-update="true"
      :auto-destroy="true"
      :autoplay="true"
      :options="swiperOptions"
      :pagination="{ clickable: false }"
    >
      <swiper-slide
        class="swiper-slide"
        v-for="(slider, index) in sliders"
        :key="index"
      >
        <a :href="getHref(slider)">
          <LazyPicture
            v-if="slider.picture"
            :src="slider.picture"
            src-placeholder="/img/no_img_src.png"
            class="blur-up"
          />
          <video v-if="slider.video" autoplay loop muted playsInline>
            <source :src="slider.video" type="video/mp4">
          </video>
        </a>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import SwiperCore, {Pagination, Autoplay } from "swiper";
import {Swiper, SwiperSlide} from "swiper/vue";

SwiperCore.use([Pagination, Autoplay]);

export default {
  components: {
    Swiper,
    SwiperSlide,
  },

  props: {
    sliders: {
      type: Array,
      required: true,
    },
    swiperOptions: {
      type: Object,
      required: true,
    },
    classItem: {
      type: String,
      required: false,
    },
  },

  methods: {
    getHref(obj) {
      if (obj.link) return obj.link
      if (obj.url && obj.url.detail) return obj.url.detail
      return '#'
    }
  }
};
</script>
