<template>
	<div class="home-bullets">
		<div class="home-bullet">
			<div class="home-bullet__icon">
				<svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
					<g clip-path="url(#clip0)">
						<path d="M51.7502 4.89909V64.0479C51.7502 64.5734 51.3236 65 50.798 65H0.952149C0.426563 65 0 64.5734 0 64.0479V4.89909C0 4.3735 0.426563 3.94694 0.952149 3.94694H50.798C51.3236 3.94694 51.7502 4.3735 51.7502 4.89909Z" fill="#BDBDBD"></path>
						<path d="M48.0497 8.67853V60.2685C48.0497 60.7941 47.6232 61.2206 47.0976 61.2206H4.65283C4.12598 61.2206 3.70068 60.7941 3.70068 60.2685V8.67853C3.70068 8.15294 4.12598 7.72638 4.65283 7.72638H47.0975C47.6232 7.72638 48.0497 8.15294 48.0497 8.67853Z" fill="#F2F2F2"></path>
						<path d="M36.4446 3.94697H31.5474L30.3772 0.308242C30.3181 0.124541 30.1471 0 29.9542 0H22.6245C22.4315 0 22.2605 0.124541 22.2015 0.308242L21.0313 3.94697H16.134C15.8886 3.94697 15.6897 4.14591 15.6897 4.39131V10.0103C15.6897 10.2557 15.8886 10.4546 16.134 10.4546H36.4445C36.6899 10.4546 36.8889 10.2557 36.8889 10.0103V4.39131C36.889 4.14591 36.69 3.94697 36.4446 3.94697Z" fill="#424F60"></path>
						<path d="M65 50.926C65 58.6866 58.6868 65 50.9263 65C43.167 65 36.8538 58.6866 36.8538 50.926C36.8538 43.1666 43.167 36.8532 50.9263 36.8532C58.6868 36.8532 65 43.1666 65 50.926Z" fill="#FF980C"></path>
						<path d="M55.7036 47.9535C55.3319 47.5815 54.729 47.5816 54.3571 47.9535L49.7652 52.5457L47.4961 50.2763C47.1242 49.9046 46.5213 49.9045 46.1496 50.2763C45.7776 50.6482 45.7776 51.2511 46.1495 51.6229L49.0919 54.5657C49.2704 54.7442 49.5126 54.8446 49.7652 54.8446C50.0179 54.8446 50.26 54.7442 50.4386 54.5657L55.7038 49.3001C56.0754 48.9281 56.0754 48.3253 55.7036 47.9535Z" fill="white"></path>
						<path d="M12.5826 19.9263C13.9825 19.9263 15.1214 18.7873 15.1214 17.3872C15.1214 15.9872 13.9825 14.8482 12.5826 14.8482C11.1827 14.8482 10.0437 15.9872 10.0437 17.3872C10.0437 18.7873 11.1827 19.9263 12.5826 19.9263Z" fill="#424F60"></path>
						<path d="M12.5826 23.5762C11.1827 23.5762 10.0437 24.7152 10.0437 26.1153C10.0437 27.5153 11.1827 28.6543 12.5826 28.6543C13.9825 28.6543 15.1214 27.5153 15.1214 26.1153C15.1214 24.7152 13.9825 23.5762 12.5826 23.5762Z" fill="#424F60"></path>
						<path d="M12.5826 32.3042C11.1827 32.3042 10.0437 33.4433 10.0437 34.8433C10.0437 36.2433 11.1827 37.3824 12.5826 37.3824C13.9825 37.3824 15.1214 36.2433 15.1214 34.8433C15.1214 33.4433 13.9825 32.3042 12.5826 32.3042Z" fill="#FF980C"></path>
						<path d="M12.5826 41.0323C11.1827 41.0323 10.0437 42.1713 10.0437 43.5713C10.0437 44.9714 11.1827 46.1104 12.5826 46.1104C13.9825 46.1104 15.1214 44.9714 15.1214 43.5713C15.1214 42.1713 13.9825 41.0323 12.5826 41.0323Z" fill="#424F60"></path>
						<path d="M12.5826 49.7603C11.1827 49.7603 10.0437 50.8993 10.0437 52.2993C10.0437 53.6994 11.1827 54.8384 12.5826 54.8384C13.9825 54.8384 15.1214 53.6994 15.1214 52.2993C15.1214 50.8993 13.9825 49.7603 12.5826 49.7603Z" fill="#FF980C"></path>
						<path d="M18.8132 18.2007H41.0715C41.5974 18.2007 42.0237 17.7744 42.0237 17.2486C42.0237 16.7227 41.5975 16.2964 41.0715 16.2964H18.8132C18.2874 16.2964 17.8611 16.7227 17.8611 17.2486C17.8611 17.7744 18.2874 18.2007 18.8132 18.2007Z" fill="#424F60"></path>
						<path d="M41.0715 25.0245H18.8132C18.2874 25.0245 17.8611 25.4508 17.8611 25.9766C17.8611 26.5024 18.2874 26.9288 18.8132 26.9288H41.0715C41.5974 26.9288 42.0237 26.5024 42.0237 25.9766C42.0237 25.4508 41.5975 25.0245 41.0715 25.0245Z" fill="#424F60"></path>
						<path d="M41.0715 33.7526H18.8132C18.2874 33.7526 17.8611 34.1789 17.8611 34.7047C17.8611 35.2305 18.2874 35.6569 18.8132 35.6569H41.0715C41.5974 35.6569 42.0237 35.2305 42.0237 34.7047C42.0237 34.1789 41.5975 33.7526 41.0715 33.7526Z" fill="#424F60"></path>
						<path d="M34.7245 42.4806H18.8132C18.2874 42.4806 17.8611 42.9069 17.8611 43.4327C17.8611 43.9586 18.2874 44.3849 18.8132 44.3849H34.7245C35.2504 44.3849 35.6767 43.9586 35.6767 43.4327C35.6767 42.9069 35.2505 42.4806 34.7245 42.4806Z" fill="#424F60"></path>
						<path d="M33.4551 51.2086H18.8132C18.2874 51.2086 17.8611 51.6349 17.8611 52.1608C17.8611 52.6866 18.2874 53.1129 18.8132 53.1129H33.4551C33.9811 53.1129 34.4073 52.6866 34.4073 52.1608C34.4073 51.6349 33.9811 51.2086 33.4551 51.2086Z" fill="#424F60"></path>
						<clipPath id="clip0">
							<rect width="65" height="65" fill="white"></rect>
						</clipPath>
					</g>
				</svg>
			</div>
			<div class="home-bullet__text">Проверяем каждый товар</div>
		</div>
		<div class="home-bullet">
			<div class="home-bullet__icon">
				<svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M52.0602 11.4114H43.3233C42.2729 11.4114 41.4214 10.56 41.4214 9.50955V6.27021C41.4214 2.81277 44.2343 0 47.6917 0C51.1492 0 53.9621 2.8129 53.9621 6.27021V9.50942C53.9621 10.56 53.1106 11.4114 52.0602 11.4114ZM45.2253 8.87555H50.1583V6.27021C50.1583 4.91017 49.0518 3.80377 47.6917 3.80377C46.3317 3.80377 45.2252 4.91029 45.2252 6.27021V8.87555H45.2253Z" fill="#424F60"></path>
					<path d="M57.7469 30.8355C57.7469 30.8355 57.7469 30.8355 57.7457 30.8355H38.0952C37.0453 30.8355 36.1934 29.9834 36.1934 28.9336V21.326C36.1934 21.3107 36.1959 21.2955 36.1959 21.2803C36.1959 21.265 36.1946 21.2485 36.1959 21.2334L36.8299 9.49864C36.8794 8.48555 37.7149 7.6893 38.7292 7.6893H56.654C57.6658 7.6893 58.5002 8.48047 58.5534 9.48975L59.6488 28.8321C59.649 30.0991 58.7708 30.8355 57.7469 30.8355Z" fill="#FF980C"></path>
					<path d="M40.3431 14.9863V28.9336C40.3431 29.9835 39.4923 30.8355 38.4412 30.8355H18.7353C17.5836 30.8355 16.6895 29.8153 16.8525 28.6661L17.7436 14.7188C17.8767 13.7818 18.6793 13.0844 19.6264 13.0844H38.4412C39.4923 13.0844 40.3431 13.9364 40.3431 14.9863Z" fill="#424F60"></path>
					<path d="M50.2093 65C53.3605 65 55.915 62.4455 55.915 59.2944C55.915 56.1432 53.3605 53.5887 50.2093 53.5887C47.0582 53.5887 44.5037 56.1432 44.5037 59.2944C44.5037 62.4455 47.0582 65 50.2093 65Z" fill="#BDBDBD"></path>
					<path d="M29.9542 65C33.1053 65 35.6598 62.4455 35.6598 59.2944C35.6598 56.1432 33.1053 53.5887 29.9542 53.5887C26.803 53.5887 24.2485 56.1432 24.2485 59.2944C24.2485 62.4455 26.803 65 29.9542 65Z" fill="#BDBDBD"></path>
					<path d="M51.6229 27.5705C51.6495 28.0904 51.4606 28.5988 51.1017 28.9767C50.7429 29.3532 50.2445 29.5675 49.7235 29.5675H30.9631C30.442 29.5675 29.945 29.3532 29.5849 28.9767C29.226 28.5988 29.0384 28.0904 29.0638 27.5705L29.4442 19.9629C29.4949 18.951 30.3304 18.1561 31.3435 18.1561H49.3432C50.3562 18.1561 51.1919 18.951 51.2425 19.9629L51.6229 27.5705Z" fill="#F2F2F2"></path>
					<path d="M61.9593 28.1449L58.6373 39.6387C57.7434 43.3741 54.4392 45.9809 50.5961 45.9809H22.8041C21.8747 42.1949 19.2805 33.1964 17.3977 25.7636H60.1183C60.7066 25.7636 61.2621 26.0362 61.6234 26.5029C61.9833 26.9683 62.1076 27.5744 61.9593 28.1449Z" fill="#FABE2C"></path>
					<path d="M29.9541 61.1986C31.0058 61.1986 31.8584 60.3461 31.8584 59.2943C31.8584 58.2426 31.0058 57.39 29.9541 57.39C28.9024 57.39 28.0498 58.2426 28.0498 59.2943C28.0498 60.3461 28.9024 61.1986 29.9541 61.1986Z" fill="#424F60"></path>
					<path d="M50.2092 61.1986C51.2609 61.1986 52.1135 60.3461 52.1135 59.2943C52.1135 58.2426 51.2609 57.39 50.2092 57.39C49.1575 57.39 48.3049 58.2426 48.3049 59.2943C48.3049 60.3461 49.1575 61.1986 50.2092 61.1986Z" fill="#424F60"></path>
					<path d="M56.9489 51.6864C56.9489 52.7363 56.0958 53.5882 55.0459 53.5882H27.7927C24.365 53.5882 21.4197 51.2586 20.63 47.9223C19.975 45.6168 20.2187 46.4775 12.863 17.0841C12.6498 16.2449 11.8969 15.6597 11.0324 15.6597H4.88149C3.83159 15.6597 2.97974 14.8078 2.97974 13.7579C2.97974 12.7067 3.83159 11.8562 4.88149 11.8562H11.0324C13.64 11.8562 15.9086 13.6208 16.5498 16.1472C16.5498 16.1472 17.4626 19.7425 18.6661 24.4956C20.5488 31.9287 23.1424 42.1954 24.0717 45.9812C24.1834 46.4331 24.271 46.7949 24.3307 47.0463C24.7128 48.6586 26.136 49.7847 27.7927 49.7847H55.0458C56.0957 49.7847 56.9489 50.6365 56.9489 51.6864Z" fill="#BDBDBD"></path>
					<path d="M16.742 51.5335C16.5339 49.5445 14.6447 48.0897 12.5349 48.0897H9.41067V46.3486C9.41067 46.2149 9.32981 46.093 9.20264 46.0345C9.07473 45.9767 8.92403 45.992 8.81376 46.0763L5.1382 48.862C5.05072 48.9289 5 49.0285 5 49.1343C5 49.2402 5.05072 49.3398 5.13747 49.4066L8.81303 52.1923C8.87992 52.2424 8.96152 52.2682 9.04312 52.2682C9.09752 52.2682 9.15192 52.2571 9.20264 52.2341C9.32981 52.1756 9.41067 52.0537 9.41067 51.92V50.1789H12.6128C13.5744 50.1789 14.4506 50.8336 14.5469 51.7396C14.6579 52.7815 13.7956 53.6611 12.7187 53.6611H9.77823C9.57534 53.6611 9.41067 53.817 9.41067 54.0093V55.4021C9.41067 55.5943 9.57534 55.7503 9.77823 55.7503H12.7187C15.0806 55.7503 16.9794 53.8164 16.742 51.5335Z" fill="#FF980C"></path>
				</svg>
			</div>
			<div class="home-bullet__text">Легкий обмен и возврат</div>
		</div>
		<div class="home-bullet">
			<div class="home-bullet__icon">
				<svg width="72" height="62" viewBox="0 0 72 62" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M27 22.2188H16.875V21.0846L20.9575 14.9112C21.0859 14.717 21.2599 14.5578 21.464 14.4477C21.6682 14.3376 21.8961 14.2799 22.1276 14.28H27V22.2188Z" fill="#BDBDBD"></path>
					<path d="M27 40.3646V33.5599H6.1875C5.44158 33.5599 4.72621 33.8586 4.19876 34.3903C3.67132 34.9221 3.375 35.6432 3.375 36.3952V50.2881C3.375 50.6641 3.52316 51.0247 3.78688 51.2905C4.0506 51.5564 4.40829 51.7057 4.78125 51.7057H67.2188C67.5917 51.7057 67.9494 51.5564 68.2131 51.2905C68.4768 51.0247 68.625 50.6641 68.625 50.2881V40.3646H27Z" fill="#424F60"></path>
					<path d="M27 34.694H5.625V33.5599L13.3615 22.8361C13.7529 22.2936 14.266 21.8521 14.859 21.5477C15.4519 21.2433 16.1079 21.0846 16.7733 21.0846H27V34.694Z" fill="#F2F2F2"></path>
					<path d="M27.2812 9.74349H67.2188C67.5917 9.74349 67.9494 9.89285 68.2131 10.1587C68.4768 10.4246 68.625 10.7852 68.625 11.1611V41.4987H25.875V11.1611C25.875 10.7852 26.0232 10.4246 26.2869 10.1587C26.5506 9.89285 26.9083 9.74349 27.2812 9.74349Z" fill="#FABE2C"></path>
					<path d="M14.625 60.7786C18.3529 60.7786 21.375 57.7321 21.375 53.974C21.375 50.2158 18.3529 47.1693 14.625 47.1693C10.8971 47.1693 7.875 50.2158 7.875 53.974C7.875 57.7321 10.8971 60.7786 14.625 60.7786Z" fill="#BDBDBD"></path>
					<path d="M57.375 60.7786C61.1029 60.7786 64.125 57.7321 64.125 53.974C64.125 50.2158 61.1029 47.1693 57.375 47.1693C53.6471 47.1693 50.625 50.2158 50.625 53.974C50.625 57.7321 53.6471 60.7786 57.375 60.7786Z" fill="#BDBDBD"></path>
					<path d="M18 36.9622H22.5V39.2305H18V36.9622Z" fill="#FFFEFD"></path>
					<path d="M6.1875 42.6328H3.375V36.3952C3.375 35.6432 3.67132 34.9221 4.19876 34.3903C4.72621 33.8586 5.44158 33.5599 6.1875 33.5599H9V39.7975C9 40.5495 8.70368 41.2707 8.17624 41.8024C7.64879 42.3341 6.93342 42.6328 6.1875 42.6328Z" fill="#FF980C"></path>
					<path d="M44.8203 35.1413C41.241 32.5129 38.7983 28.6004 37.9956 24.2103L37.1528 18.8164L47.2638 14.2714L57.3747 18.8164L56.5232 24.2552C55.7139 28.6829 53.2364 32.623 49.6095 35.2502L47.2728 36.9622L44.8203 35.1413Z" fill="#FF980C"></path>
					<path d="M46.1262 29.0234C45.9784 29.0236 45.8321 28.9943 45.6956 28.9373C45.5591 28.8803 45.4351 28.7966 45.3308 28.6911L41.9558 25.2888L43.5467 23.685L46.1262 26.2854L52.0808 20.2827L53.6717 21.8864L46.9217 28.6911C46.8174 28.7966 46.6934 28.8803 46.5568 28.9373C46.4203 28.9943 46.274 29.0236 46.1262 29.0234Z" fill="white"></path>
					<path d="M29.25 13.1458H31.5V15.4141H29.25V13.1458Z" fill="#FFFAF6"></path>
					<path d="M29.25 35.8281H31.5V38.0964H29.25V35.8281Z" fill="#FFFAF6"></path>
					<path d="M63 13.1458H65.25V15.4141H63V13.1458Z" fill="#FFFAF6"></path>
					<path d="M63 35.8281H65.25V38.0964H63V35.8281Z" fill="#FFFAF6"></path>
					<path d="M14.625 56.2422C15.8676 56.2422 16.875 55.2267 16.875 53.974C16.875 52.7213 15.8676 51.7057 14.625 51.7057C13.3824 51.7057 12.375 52.7213 12.375 53.974C12.375 55.2267 13.3824 56.2422 14.625 56.2422Z" fill="#FEFEFE"></path>
					<path d="M57.375 56.2422C58.6176 56.2422 59.625 55.2267 59.625 53.974C59.625 52.7213 58.6176 51.7057 57.375 51.7057C56.1324 51.7057 55.125 52.7213 55.125 53.974C55.125 55.2267 56.1324 56.2422 57.375 56.2422Z" fill="#FEFEFE"></path>
					<path d="M2.25 13.1458H11.25V15.4141H2.25V13.1458Z" fill="#B8B8B8"></path>
					<path d="M13.5 13.1458H15.75V15.4141H13.5V13.1458Z" fill="#B8B8B8"></path>
					<path d="M12.375 1.80469H30.375V4.07292H12.375V1.80469Z" fill="#B8B8B8"></path>
					<path d="M32.625 1.80469H34.875V4.07292H32.625V1.80469Z" fill="#B8B8B8"></path>
					<path d="M42.75 4.07291H60.75V6.34114H42.75V4.07291Z" fill="#B8B8B8"></path>
					<path d="M63 4.07291H65.25V6.34114H63V4.07291Z" fill="#B8B8B8"></path>
					<path d="M23.625 57.3763H43.875V59.6445H23.625V57.3763Z" fill="#B8B8B8"></path>
					<path d="M46.125 57.3763H48.375V59.6445H46.125V57.3763Z" fill="#B8B8B8"></path>
				</svg>
			</div>
			<div class="home-bullet__text">Доставка вовремя - 100%</div>
		</div>
	</div>
</template>