<template>
  <div
    class="home-feature"
    v-for="feature in features"
    :key="feature.id"
  >
    <a :href="feature.url.detail">
      <span class="home-feature__img">
        <LazyPicture
          src-placeholder="/img/no_img_src.png"
          :src="feature.picture"
          :alt="feature.name"
          class="blur-up"
        />
      </span>
      <span class="home-feature__description">{{ feature.name }}</span>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    features: {
      type: Array,
      required: true,
    },
  },
};
</script>
