<template>
  <div class="stock-links">
    <template v-for="stock in stocks" :key="stock.id">
      <a :href="stock.url.detail">
        <img :src="stock.picture" :alt="stock.name"/>
      </a>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    stocks: {
      type: Array,
      required: true,
    },
  },
};
</script>
